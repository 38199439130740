$theme-colors: (
    "primary": #003152,
    "info" :  #D8EFFF,
    "secondary" : #E5E5E5,

);

$grid-gutter-width : 20px;
$grid-gutter-height : 22px;
$form-check-input-checked-bg-color : #003152;
$form-check-input-checked-color: #003152;

@import 'node_modules/bootstrap/scss/bootstrap.scss';

body{
  font-family: Roboto;
  font-style: normal;
}

.full-height{
  min-height: 100vh;
}

.dfast-hr{
  border: 1px solid #A9A9A9
}

.dfast-description{
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  color: #696969;
}

.forgotPassword-font{
  
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #696969;
  float: right;
}

.center-align{
  text-align: center;
}

.loginCardWrapper{
  // max-width: 780px;

  // margin-left: auto;
  // margin-right: auto;
  margin-top: 9.5%;
  //margin-bottom: 8.5%;
  max-width: 872px;
  // marg
  // left: 200px;
}

.loginCard{
  padding-top: 64px;
  padding-bottom: 44px;
  padding-left: 3vw;
  padding-right: 3vw;
}
.logoCard{
  padding-top: 143px;
  padding-bottom: 180px;
  max-width: 872px;
}

.form-header{
  
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  margin-bottom: 10px;
}

.btn-signup{
  margin-top: 69px;
  background: rgba(0, 49, 82, 0.1);
  border: 1px solid rgba(0, 49, 82, 0.21);
  box-sizing: border-box;
  border-radius: 8px;
}

.text-dont-have-an-account{
 
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 49, 82, 0.56);
}

.text-signup{
  
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: contents;
}

.text-remember-me{
  
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #696969;

}


.navbar-light .navbar-nav .nav-link{
  color: rgba(173,173,173,1);
}

.nav-link.active {
  text-decoration: none;
  border-bottom: 4px solid #333333;
}

.pr-9{
  padding-right: 88px;
}

.text-profileName{
  font-size: 16px;
  // line-height: 19px;
  /* identical to box height */

  text-align: right;

}

.text-administrator{
  font-size: 12px;
  // line-height: 14px;
  // /* identical to box height */

  text-align: right;

  color: #696969;

}

.mr-7{
  margin-right: 68px;
}

.mr-10{
  margin-right: 99px;
}

.btn-dropdown{
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */

  text-align: left;
  min-height: 40px;
}

.btn-dropdown.active{
  background: #333333;
  color: #FFFFFF;
}

.btn-dropdown.in-active{
  background: #FFFFFF;
  color : #2C3E59;

}

.display-none{
  display: none;
}
.display-block{
  display: block;
}

.border-dashed{
  border: 2px dashed #E5E5E5;
box-sizing: border-box;
}

.btn-migrate{
  
}

.text-check-box{
  font-weight: normal;
  font-size: 14px;
  // line-height: 16px;
  color: #696969;
}
  
.form-check-input{
  color: #003152;
  border: 1px solid #003152;
  box-sizing: border-box;
  border-radius: 2px;
}
 
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:#696969;;
  border-radius: 2px;
}

.view-header{
  font-size: small;
  font-weight: 700;
  text-decoration: underline;
}

.bg-grey{
  background: #F0F0F0;

}

.card-header{
  padding: 12px 12px;
}

.text-border{
  color: #adadad;
}

